import { createCatalog, createWixConnection } from 'actions/channel';
import { attachUserCatalog, updateCatalog } from 'actions/user';
import router from 'next/router';
import innerText from 'react-innertext';
import { dispatch, getState } from 'redux/store';
import {
  ADMIN_TOKEN_LIFE,
  ADMIN_TOKEN_NAME,
  TOKEN_LIFE,
  TOKEN_NAME,
} from 'tools/constants';
import { getCookie, removeCookie, setCookie } from 'tools/cookie';

import message from '../components/utils/message';
import { defaultTranslations } from '../hooks/useTranslation';

export function setToken(token) {
  setCookie(TOKEN_NAME, token, TOKEN_LIFE);
}

export function getToken() {
  return getCookie(TOKEN_NAME);
}
export function setAdminToken(token) {
  setCookie(ADMIN_TOKEN_NAME, token, ADMIN_TOKEN_LIFE);
}

export function getAdminToken() {
  return getCookie(ADMIN_TOKEN_NAME);
}

export function removeToken() {
  removeCookie(TOKEN_NAME);
}
export function removeAdminToken() {
  removeCookie(ADMIN_TOKEN_NAME);
}

export function getFormattedDate(date) {
  try {
    return Intl.DateTimeFormat(navigator?.language, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(new Date(date));
  } catch (error) {
    return 'Invalid time';
  }
}

export function getFormattedDateWithTime(date) {
  try {
    return Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(new Date(date));
  } catch (error) {
    return 'Invalid time';
  }
}

export function range(start, end) {
  return new Array(end - start + 1).fill().map((_, i) => i + start);
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function getQueryString(params) {
  let queryString = '';
  const queryParams = [];
  if (params) {
    Object.keys(params).forEach((key) => {
      queryParams.push(
        `${key}=${
          typeof params[key] !== 'string'
            ? JSON.stringify(params[key])
            : params[key]
        }`
      );
    });

    queryString = `?${queryParams.join('&')}`;
  }

  return queryString;
}

export function getFileAddress(picture, small = false) {
  let url = '';
  let blob = true;
  if (picture) {
    if (typeof picture === 'string') {
      url = picture;
    } else {
      url = picture.url;
      blob = picture.blob;
    }
  }

  if (blob) {
    return `${
      !small ? process.env.PHOTO_ADDRESS : process.env.PHOTO_ADDRESS_SMALL
    }/${url}`;
  }
  return url;
}

export function isServer() {
  return typeof window === 'undefined';
}

export function getProductPrice(product, quantity = 1) {
  return getPrice(product?.bestTaxable, quantity);
}

export function getPrice(price, quantity = 1) {
  return formatPrice(price * quantity);
}

export function formatPrice(price) {
  return (price || 0).toFixed(2);
}

export function concatObjectProperties(object, separator, keys) {
  if (!keys) {
    keys = object ? Object.keys(object) : [];
  }

  const values = [];

  keys.forEach((key) => {
    if (typeof key === 'function') {
      values.push(key(object));
    } else {
      object?.[key] && values.push(object[key]);
    }
  });

  return values.join(separator);
}

export function getCountryById(id) {
  const { countries } = getState();

  return countries.find((c) => c._id === id);
}

export function getCountryByCode(code) {
  const { countries } = getState();

  return countries.find((c) => c.countrycode === code);
}

export function getFullAddress(address) {
  return concatObjectProperties(address, ', ', [
    'streetType',
    'streetName',
    'city',
    'province',
    (data) => getCountryByCode(data?.countrycode)?.country,
    'apartment',
    'zip',
  ]);
}

export function getFullPhone(phone) {
  return concatObjectProperties(phone, ' - ');
}

export const renderIconCatalog = (platform) => {
  switch (platform) {
    case 'amazon':
    case 'AMAZON':
      return '/images/channel/logo/logo-amazon.png';
    case 'prestashopPlugin':
    case 'PRESTASHOP':
      return '/images/channel/logo/logo-prestashop.png';
    case 'ebay':
    case 'EBAY':
      return '/images/channel/logo/logo-ebay.png';
    case 'woocommercePlugin':
    case 'WOOCOMMERCE':
      return '/images/channel/logo/logo-woocommerce.png';
    case 'wix':
    case 'WIX':
      return '/images/channel/logo/logo-wix.png';
    case 'turnkey':
      return '/images/channel/turnkey-channel.png';
    case 'squarespace':
    case 'SQUARESPACE':
      return '/images/channel/logo/logo-squarespace.png';
    case 'ECWID':
      return '/images/channel/logo/logo-ecwid.png';
    case 'ekm':
    case 'EKM':
      return '/images/channel/logo/logo-ekm.png';
    case 'shopify':
    case 'SHOPIFY':
      return '/images/channel/logo/logo-shopify.png';
    case 'socialEcommerce':
      return '/images/channel/logo/logo-social.svg';
    case 'woocommerce':
      return '/images/channel/logo/logo-woocommerce.png';
    case 'shopifyBrandsSync':
    case 'SHOPIFYBRANDSSYNC':
      return '/images/channel/logo/logo-shopify.png';
    default:
      return '';
  }
};

export function getValueByIndex(obj, is, value) {
  if (typeof is == 'string') return getValueByIndex(obj, is.split('.'), value);
  else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value);
  else if (is.length == 0 || !obj) return obj;
  else return getValueByIndex(obj[is[0]], is.slice(1), value);
}

export function exportCSV(header, data, fileName = 'export', delimiter = ',') {
  let csv = header.join(delimiter) + '\n';
  data.forEach((array) => {
    csv += array.join(delimiter) + '\n';
  });

  const csvData = new Blob([csv], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(csvData);

  const hiddenElement = document.createElement('a');
  hiddenElement.href = csvUrl;
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName + '.csv';
  hiddenElement.click();
}

export function downloadCSV(selectedRows, activeColumns) {
  const columns = activeColumns.filter((c) => c.exportable !== false);

  const headers = columns.map((c) => c.title);

  const rows = selectedRows.map((record) =>
    columns.map((c, index) =>
      c.exportRender
        ? c.exportRender(getValueByIndex(record, c.key), record, index)
        : c.render
        ? innerText(c.render(getValueByIndex(record, c.key), record, index))
        : getValueByIndex(record, c.key)
    )
  );

  exportCSV(headers, rows);
}

export function generatePad(id) {
  return id && ` ${(id + '').padStart(5, '0')}`;
}
export function generateBD(id) {
  return id && ` BD-${(id + '').padStart(5, '0')}`;
}
export function generateBDR(id) {
  return id && ` BDR-${(id + '').padStart(5, '0')}`;
}

export const getDeepKeys = (keysArray, obj, value) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object') {
      getDeepKeys(keysArray, obj[key], [...value, key]);
    } else {
      keysArray.push([...value, key].join('.'));
    }
  });
};

export const getValueFromDeepKey = (obj, deepKey) => {
  let value = { ...obj };
  const keys = deepKey.split('.');
  for (const key of keys) {
    if (value?.[key]) {
      value = value?.[key];
    }
  }
  return value;
};

export const getObjectFromDeepKeyValues = (deepKeysValues = {}) => {
  const newObject = {};
  Object.keys(deepKeysValues).forEach((deepKey) => {
    const keys = deepKey.split('.');
    const lastKey = keys.pop();
    let value = newObject;
    for (let key of keys) {
      if (!value[key]) value[key] = {};

      value = value[key];
    }
    value[lastKey] = deepKeysValues[deepKey];
  });
  return newObject;
};

export const getPageKeys = () => {
  const platformTranslations = defaultTranslations();
  const platformTranslationsKeys = [];
  getDeepKeys(platformTranslationsKeys, platformTranslations, []);
  const pageKeyArray = [];
  platformTranslationsKeys.forEach((key) => {
    const splittedKey = key.split('.');
    let pageKey = '';
    if (splittedKey.length > 2) {
      pageKey = `${splittedKey[0]}.${splittedKey[1]}`;
    } else {
      pageKey = splittedKey[0];
    }
    if (!pageKeyArray.includes(pageKey)) pageKeyArray.push(pageKey);
  });
  return pageKeyArray;
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const detectChannelCatalog = (d) => {
  try {
    if (d.connections.length > 0) {
      if (d.connections[0].component === 'woocommercePlugin') {
        return 'woocommercePlugin';
      }
      if (d.connections[0].component === 'turnkey') {
        return 'turnkey';
      }
      if (d.connections[0].component === 'prestashopPlugin') {
        return 'prestashopPlugin';
      }
      if (d.connections[0].component === 'SHOPIFYBRANDSSYNC') {
        return 'shopifyBrandsSync';
      }
      if (d.connections[0].component === 'shopifyPlugin') {
        return 'shopifyPlugin';
      }
      if (d.connections[0].component === 'minisitePlugin') {
        return 'minisitePlugin';
      }
      if (d.connections[0].component === 'ebay') {
        return 'ebay';
      }
      if (d.connections[0].component === 'amazon') {
        return 'amazon';
      }
      if (d.connections[0].component === 'cdiscount') {
        return 'CDiscount';
      }
      if (d.connections[0].component === 'WIX') {
        return 'WIX';
      }
      if (d.connections[0].component === 'SQUARESPACE') {
        return 'SQUARESPACE';
      }
      if (d.connections[0].component === 'ECWID') {
        return 'ECWID';
      }
      if (d.connections[0].component === 'SHOPIFY') {
        return 'SHOPIFY';
      }
      if (d.connections[0].component === 'socialEcommerce') {
        return 'socialEcommerce';
      }
      if (d.connections[0].component === 'WOOCOMMERCE') {
        return 'woocommerce';
      }
      try {
        if (d.connections[0].service !== 'minisitePlugin') {
          delete d.connections[0].minisitePlugin;
        }
      } catch (e) {}
    }
  } catch (e) {}
};

export const detectChannelConnection = (d) => {
  try {
    if (d.connections.length > 0) {
      if (
        d.connections[0].service === 'woocommercePlugin' &&
        d.connections[0].url
      ) {
        return 'woocommercePlugin';
      }
      if (d.connections[0].service === 'turnkeyPlugin') {
        return 'turnkeyPlugin';
      }
      if (
        d.connections[0].service === 'prestashopPlugin' &&
        d.connections[0].url &&
        d.connections[0].prestashopPlugin.name
      ) {
        return 'prestashopPlugin';
      }
      if (
        d.connections[0].service === 'shopifyBrandsSync' &&
        d.connections[0].shopifyPlugin.name
      ) {
        return 'shopifyBrandsSync';
      }
      if (d.connections[0].service === 'minisitePlugin') {
        return 'minisitePlugin';
      }
      if (d.connections[0].service === 'tars') {
        if (d.connections[0].tars.channelName.search('Ebay') !== -1) {
          return 'ebay';
        }
        if (d.connections[0].tars.channelName.search('Amazon') !== -1) {
          return 'amazon';
        }
        if (d.connections[0].tars.channelName.search('CDiscount') !== -1) {
          return 'CDiscount';
        }
      }
      if (d.connections[0].service === 'rutter') {
        if (d.connections[0].rutter.platform.search('WIX') !== -1) {
          return 'WIX';
        }
        if (d.connections[0].rutter.platform.search('SQUARESPACE') !== -1) {
          return 'SQUARESPACE';
        }
      }
      if (
        d.connections[0].service === 'wix' &&
        d.connections[0].wix?.instanceId
      ) {
        return 'WIX';
      }
      if (d.connections[0].service === 'ecwid') {
        if (d.connections[0].ecwid.storeId) {
          return 'ECWID';
        }
      }
      if (d.connections[0].service === 'ekm') {
        if (d.connections[0].ekm.storeId) {
          return 'EKM';
        }
      }
      if (d.connections[0].service === 'woocommerce') {
        if (d.connections[0].woocommerce.baseUrl) {
          return 'woocommerce';
        }
      }
      if (d.connections[0].service === 'shopify') {
        if (d.connections[0].shopify.shop) {
          return 'SHOPIFY';
        }
      }
      if (d.connections[0].service === 'squarespace') {
        if (d.connections[0].squarespace.storeId) {
          return 'SQUARESPACE';
        }
      }
      if (d.connections[0].service === 'socialEcommerce') {
        if (d.connections[0].socialEcommerce.paymentType) {
          return 'socialEcommerce';
        }
      }
      try {
        if (d.connections[0].service !== 'minisitePlugin') {
          delete d.connections[0].minisitePlugin;
        }
      } catch (e) {}
    }
  } catch (e) {}
};

export const mergeRows = (checked, rows, selectedRows, rowKey) => {
  let newRows = [];
  if (checked) {
    newRows = [
      ...selectedRows,
      ...rows.filter(
        (row) =>
          !selectedRows.find(
            (selectedRow) => selectedRow[rowKey] === row[rowKey]
          )
      ),
    ];
  } else {
    newRows = selectedRows.filter(
      (selectedRow) => !rows.find((row) => row[rowKey] === selectedRow[rowKey])
    );
  }

  return { rows: newRows };
};

export const runTimer = (endDate, timeZone) => {
  let countDown = new Date(
    endDate.toString().split('.')[0] + timeZone
  ).getTime();
  let now = new Date().getTime();

  let difference = countDown - now;
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / day),
      hours: Math.floor((difference % day) / hour),
      minutes: Math.floor((difference % hour) / minute),
      seconds: Math.floor((difference % minute) / second),
    };
  }

  return timeLeft;
};
export const pendingStatuses = [0];
export const processingStatuses = [1, 2];
export const completedStatuses = [3, 4, 7, 9];
export const refundableStatuses = [3, 9];
export const notReceivedStatutes = [5];
export const refundStatusLabels = {
  pending: 'pending',
  processing: 'processing',
  not_received: 'not_received',
  received: 'received',
};

export const mapReturnData = (returnItems) => {
  let isRefundable = false;
  let creditNoteId = null;
  let status = 'pending';
  const { returnCode, pdfLink } =
    returnItems.find((item) => item.returnCode && item.pdfLink) || {};

  if (returnItems.every((item) => pendingStatuses.includes(item.status))) {
    status = 'pending';
  }

  if (returnItems.every((item) => processingStatuses.includes(item.status))) {
    status = 'processing';
  }

  if (returnItems.every((item) => notReceivedStatutes.includes(item.status))) {
    status = 'not_received';
  }

  if (
    returnItems.every(
      (item) => completedStatuses.includes(item.status) && item?.creditNote?._id
    )
  ) {
    status = 'received';
    creditNoteId = returnItems?.find((item) => item?.creditNote?._id)
      ?.creditNote?._id;
  }

  if (
    returnItems.some(
      (item) =>
        refundableStatuses.includes(item.status) && !item?.creditNote?._id
    )
  ) {
    isRefundable = true;
  }

  if (returnItems.every((item) => completedStatuses.includes(item.status))) {
    status = 'received';
  }

  return { status, isRefundable, creditNoteId, pdfLink, returnCode };
};

export const objectsAreEquals = (obj1, obj2) => {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;

  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
    );
  }
  return false;
};

export const calculateEcredit = (total, userEcredit) => {
  if (total - userEcredit <= 0 || total - userEcredit >= 1) {
    return Math.min(total, userEcredit);
  } else {
    return total - 1;
  }
};

export const handleWixConnection = async () => {
  const { user, UI, permissions } = getState();
  console.log('wix connection', UI.wixToken);
  if (UI.wixToken) {
    const hasAlreadyConnectedPublicToken = user.userCatalogs.find(
      (catalog) => catalog?.connections?.[0]?.component === 'WIX'
    );
    if (!hasAlreadyConnectedPublicToken) {
      const emptyCatalog = user.userCatalogs.find(
        (catalog) =>
          catalog.connections.length === 0 ||
          catalog.connections[0].component === 'socialEcommerce'
      );
      // Se c'è un catalogo senza connessione
      if (emptyCatalog) {
        dispatch(
          updateCatalog(
            emptyCatalog?._id,
            {
              connections: [{ service: 'wix', component: 'WIX' }],
              name: 'WIX',
            },
            async () => {
              await dispatch(
                createWixConnection({
                  catalogId: emptyCatalog._id,
                  publicToken: UI.wixToken,
                })
              );
              await dispatch(attachUserCatalog());
              await router.push(`/wix/pricing/${emptyCatalog._id}`);
            }
          )
        );
      } else {
        const canHaveMoreCatalogs =
          !user.userCatalogs.length ||
          user.userCatalogs?.length < user?.catalogNumber;

        // se è possibile creare un nuovo catalogo
        if (canHaveMoreCatalogs) {
          dispatch(
            createCatalog({
              ids: [],
              connections: [{ service: 'wix', component: 'WIX' }],
              name: 'My first list',
              language: 'en_US',
              currency: 'USD',
              onSuccess: async (data) => {
                await dispatch(
                  createWixConnection({
                    catalogId: data._id,
                    publicToken: UI.wixToken,
                  })
                );
                await dispatch(attachUserCatalog());
                await router.push(`/channel/${data._id}/edit/channel`);
                if (permissions.IS_FREE_USER) {
                  await router.push(`/wix/pricing/${data._id}`);
                } else {
                  await router.push(`/`);
                }
              },
              onComplete: () => {},
            })
          );
        } else {
          message.error(
            'You already have the maximum number of wix sites connected'
          );
          await router.push(`/`);
        }
      }
    } else {
      message.success('Your site is already connected');
      await router.push(`/`);
    }
  } else {
    await router.push(`/`);
  }
};

export const shareProduct = ({ title, text, product, referralCode }) => {
  const url = `${process.env.DASHBOARD_URL}/product/${product?.brand}-${
    product?.attributes?.subcategory
  }-${product?.code}-${product?.id}${
    !!referralCode?.length ? `?referralCode=${referralCode}` : ''
  }`;
  console.log('url', url);

  navigator
    .share({
      title,
      text,
      url,
    })
    .then(console.log)
    .catch(console.log);
  setTimeout(() => {
    const container = document.getElementById('shareAPIPolyfill-container');
    if (container) {
      const toolIcon = container?.querySelectorAll('.tool-icon');
      if (toolIcon) {
        Array.from(toolIcon).forEach(function (tool) {
          tool.addEventListener('click', () => {
            switch (tool.dataset.tool) {
              case 'copy': {
                navigator.clipboard.writeText(url);
                break;
              }
            }
          });
        });
      }
    }
  }, 0);
};

export const shareCatalog = ({ title, text, query = {} }) => {
  const queryString = Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&');
  const url = `${process.env.DASHBOARD_URL}/product${
    !!queryString?.length && `?${queryString}`
  }`;

  navigator
    .share({
      title,
      text,
      url,
    })
    .then(console.log)
    .catch(console.log);
  setTimeout(() => {
    const container = document.getElementById('shareAPIPolyfill-container');
    if (container) {
      const toolIcon = container?.querySelectorAll('.tool-icon');
      if (toolIcon) {
        Array.from(toolIcon).forEach(function (tool) {
          tool.addEventListener('click', () => {
            switch (tool.dataset.tool) {
              case 'copy': {
                navigator.clipboard.writeText(url);
                break;
              }
            }
          });
        });
      }
    }
  }, 0);
};

export function generateString(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const dateToString = (date, isEndDate) => {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num;
    };

  if (isEndDate) {
    return encodeURIComponent(
      date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours() + 23) +
        ':' +
        pad(date.getMinutes() + 59) +
        ':' +
        pad(date.getSeconds() + 59) +
        dif +
        pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' +
        pad(Math.abs(tzo) % 60)
    );
  } else {
    return encodeURIComponent(
      date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        dif +
        pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' +
        pad(Math.abs(tzo) % 60)
    );
  }
};

export function parseQueryString(url) {
  const query = {};
  const pathname = url.split('?')[0];

  if (url.includes('?')) {
    const queryString = url.split('?')[1];
    const queryParams = queryString.split('&');

    for (const param of queryParams) {
      const [key, value] = param.split('=');

      if (key && value && key !== 'lang') {
        query[key] = value.replace(/\+/g, ' ').replace(/%2C/g, ',');
      }
    }
  }

  return { query, pathname };
}

export const getPlanName = (plan) => {
  switch (plan) {
    case 'dropeasyenterprise':
      return 'Enterprise';
    case 'dropeasy':
      return 'Professional';
    case 'droppybasic':
      return 'Plugin';
    case 'droppyplus':
      return 'Plugin +';
    case 'droppylight':
      return 'File';
    case 'droppystarter':
      return 'Selection';
    case 'bsocial':
      return 'Social';
    case 'dropeasysite':
      return 'Easy';
    default:
      return plan?.toUpperCase();
  }
};

export function generateObjectId() {
  return (
    hex(Date.now() / 1000) +
    ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16))
  );
}

function hex(value) {
  return Math.floor(value).toString(16);
}
